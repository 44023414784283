import React, { useContext } from 'react'
import classNames from 'classnames'
import { AppContext } from '../../contexts/AppContext'

interface Props {
  className?: string
}

export const CartItemCount: React.FC<Props> = ({ className }) => {
  const { order, cartNotifications } = useContext(AppContext)

  return order?.items.length ? (
    <div className={classNames('cart-item-count transition-colors duration-500', { 'animate-bounce bg-green-400': cartNotifications.length > 0 }, className)}>
      {order?.items.length}
    </div>
  ) : null
}
