import { Button, Drawer, DrawerHeader, DrawerToggleButton, ElementProps, Icon, Nav } from 'journey-ui'
import React, { useContext } from 'react'
import classNames from 'classnames'
import { CaretLeft, ForkKnife, ShoppingCart } from 'phosphor-react'
import { AppContext } from '../contexts/AppContext'
import { BrandLogo } from './BrandLogo'
import { UserAvatar } from './UserAvatar'
import { CartItemCount } from './Menu/CartItemCount'

interface Props extends ElementProps<'div'>{
  brand: Brand
  backUrl?: string
}

export const DrawerButton = (props) => <Button {...props} className={classNames(props.className, 'w-full font-bold border-none')}>{props.children}</Button>

export const AppHeader: React.FC<Props> = ({ brand, backUrl }) => {
  const { setCartModalOpen, order, user } = useContext(AppContext)

  return (
    <Nav>
      {backUrl ? <Button href={backUrl} className="absolute top-3 left-2 md:relative md:top-0 md:left-0 border-none text-lg"><Icon src={CaretLeft} /></Button> : null}
      <div className="flex items-center flex-grow justify-center md:justify-start space-x-2 px-0 md:px-4">
        <BrandLogo />
      </div>
      <div className="items-center md:flex hidden">
        {brand.links.map((link) => <Button className="nav-button" key={link.url} href={link.url.startsWith('http') ? link.url : `/${link.url}`}>{link.name}</Button>)}

        <Button href="/location" className="nav-button">
          Location
        </Button>

        {user ? <UserAvatar user={user} /> : (
          <Button className="nav-button" href="/login">
            Login
          </Button>
        )}

        <Button className="nav-button" onClick={() => setCartModalOpen(true)}>
          <div className="relative">
            <Icon src={ShoppingCart} />
            <CartItemCount />
          </div>
        </Button>

        <Button href="/menu" className="nav-button primary-nav-button">
          <Icon src={ForkKnife} />
          <span>Menu & Order Online</span>
        </Button>
      </div>

      <DrawerToggleButton className="absolute right-2 top-2 md:hidden" />

      <Drawer>
        <div>
          <DrawerHeader>
            <BrandLogo />
          </DrawerHeader>
          {brand.links.map((link) => <Button className="w-full border-none" key={link.url} href={link.url}>{link.name}</Button>)}
          <DrawerButton href="/location">Location</DrawerButton>
          <DrawerButton href="/menu">Menu & Order Online</DrawerButton>
          <DrawerButton href="/cart">Cart</DrawerButton>
          {user ? (
            <>
              <DrawerButton href="/user/orders">Orders</DrawerButton>
              <DrawerButton href="/user/settings">Settings</DrawerButton>
              {brand.has_rewards ? <DrawerButton href="/user/rewards">Rewards</DrawerButton> : null}
            </>
          ) : <DrawerButton href="/login">Login</DrawerButton>}
        </div>

        <div className="flex flex-grow items-end flex-col-reverse">
          <DrawerButton href="/returns">Returns</DrawerButton>
          <DrawerButton href="/privacy">Privacy</DrawerButton>
          <DrawerButton href="/terms">Terms</DrawerButton>
        </div>
      </Drawer>
    </Nav>
  )
}
