import React, { useContext } from 'react'
import { ElementProps, A, Logo } from 'journey-ui'
import { AppContext } from '../contexts/AppContext'
import { awsImageUrl } from '../helpers'

interface Props extends ElementProps<'div'> {

}

export const BrandLogo: React.FC<Props> = (props) => {
  const { brand } = useContext(AppContext)

  return brand.logo
    ? <Logo size={{ w: 200, h: 50 }} src={awsImageUrl(brand.logo)} href="/" />
    : <A className="heading-font text-xl md:text-2xl font-bold color-inherit px-2" href="/">{brand.name}</A>
}
