import React, { useContext } from 'react'
import { Avatar, Button, enhancedFetch, Select, SelectButton, SelectDropdown } from 'journey-ui'
import { useRouter } from 'next/router'
import { AppContext } from '../contexts/AppContext'
import { awsImageUrl } from '../helpers'

export const UserAvatar = ({ user }) => {
  const router = useRouter()
  const { setUser, brand } = useContext(AppContext)

  const onLogoutClick = async () => {
    await enhancedFetch('/api/auth/logout', {
      method: 'POST',
    })

    setUser(null)
    router.push('/')
      .catch(console.error)
  }

  return (
    <Select className="hidden md:inline-flex">
      <SelectButton className="nav-button">
        <Avatar src={awsImageUrl(user.avatar)} />
      </SelectButton>
      <SelectDropdown>
        {user.is_pizzamico_team ? (
          <Button className="w-full border-none rounded-none" href="/admin">
            Admin
          </Button>
        ) : null}
        <Button className="w-full border-none rounded-none" href="/user/orders">
          Orders
        </Button>
        <Button className="w-full border-none rounded-none" href="/user/settings">
          Settings
        </Button>
        {brand.has_rewards ? <Button className="w-full border-none rounded-none" href="/user/rewards">Rewards</Button> : null}
        <Button className="w-full border-none rounded-none" onClick={onLogoutClick}>
          Logout
        </Button>
      </SelectDropdown>
    </Select>
  )
}
