import { Button, ElementProps, Footer, Icon, Journey } from 'journey-ui'
import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { CreditCard, DotsThree, ForkKnife, ShoppingCart } from 'phosphor-react'
import { AppContext } from '../contexts/AppContext'
import { CartItemCount } from './Menu/CartItemCount'
import { useCheckoutClick } from '../hooks/useCheckoutClick'

interface Props extends ElementProps<'div'> {

}

const FooterButton = (props) => {
  const { children, activePattern, className } = props
  const router = useRouter()
  const isActive = router.asPath.includes(activePattern)

  return (
    <Button
      {...props}
      className={classNames(className,
        'flex-grow relative flex-col hover:bg-white hover:text-primary space-y-1 rounded-none border-none',
        { 'text-primary': isActive, 'text-gray-500': !isActive })}
    >
      {children}
    </Button>
  )
}

const FooterButtonText = Journey.div('text-xs')

const MobileFooter = (props) => {
  const { setCartModalOpen, order, cartNotifications } = useContext(AppContext)
  const onCheckoutClick = useCheckoutClick()

  return (
    <Footer className={classNames(props.className, 'justify-center p-0 md:hidden sticky bottom-0 app-footer')}>
      <div className="max-w-[1080px] flex w-full">
        <FooterButton href="/menu" activePattern="/menu">
          <Icon src={ForkKnife} />
          <FooterButtonText>Menu</FooterButtonText>
        </FooterButton>
        <FooterButton onClick={() => setCartModalOpen(true)}>
          <Icon src={ShoppingCart} />
          <CartItemCount className="top-0 left-[52%] pt-0.5" />
          <FooterButtonText>
            <span>Cart</span>
          </FooterButtonText>
        </FooterButton>
        {order ? (
          <FooterButton onClick={onCheckoutClick} activePattern="/checkout">
            <Icon src={CreditCard} />
            <FooterButtonText>Checkout</FooterButtonText>
          </FooterButton>
        ) : null}
      </div>
    </Footer>
  )
}

export const AppFooter: React.FC<Props> = (props) => {
  const { brand } = useContext(AppContext)

  return (
    <>
      <MobileFooter {...props} />
      <div className="justify-center hidden md:flex p-4">
        <div className="content-container text-xs border-t border-gray-200 py-4">
          <div>
            {brand.name}
          </div>
          <div>
            {`${brand.address}, ${brand.city_name}, ${brand.state_abbreviation}`}
          </div>
          <div>
            {brand.phone_number}
          </div>
          <div>
            ©
            {' '}
            {(new Date()).getFullYear()}
            {' '}
            Pizzamico, LLC
          </div>
        </div>
      </div>
    </>
  )
}
