import { useContext } from 'react'
import { DocumentStyleContext } from '../contexts/DocumentStyleContext'
import { amplifyColor } from '../helpers'

function empty(str) {
  return (!str || str.length === 0)
}

export function useCssVars(brand: Brand) {
  const headerBgColor = empty(brand.css_vars?.header_bg_color) ? '30,30,30' : brand.css_vars?.header_bg_color
  const headerTextColor = empty(brand.css_vars?.header_text_color) ? '255,255,255' : brand.css_vars?.header_text_color
  const primaryColor = empty(brand.css_vars?.primary_color) ? '178,30,35' : brand.css_vars.primary_color

  const vars = {
    ...(brand?.css_vars ?? {}),
    primary: primaryColor,
    secondary: amplifyColor(primaryColor, 1.3),
    header_bg_color: headerBgColor,
    header_text_color: headerTextColor,
  }

  return `
  :root {
    ${Object.keys(vars).map((varName) => `--${varName}: ${vars[varName]} !important`).join(';\n')}
  }
  `
}
